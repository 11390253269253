import * as React from 'react';
import Layout from '../components/Layout';

const AboutPage = () => {
    return (
        <Layout pageTitle='About me'>
            <p>This is my about page</p>
        </Layout>
    )
}

export default AboutPage;